import {
  GET_BOOKING_LIST_ERROR,
  GET_BOOKING_LIST,
  BOOKING_LIST_LOADING,
  GET_BOOKING_BY_ID_LOADING,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_ERROR
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOKING_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_BOOKING_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_BOOKING_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case GET_BOOKING_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_BOOKING_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
