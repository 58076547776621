import { combineReducers } from "redux";
import author from "./Author/reducer";
import authorForm from "./AuthorForm/reducer";
import blog from "./Blog/reducer";
import blogForm from "./BlogForm/reducer";
import category from "./Category/reducer";
import categoryForm from "./CategoryForm/reducer";
import categoryType from "./CategoryType/reducer";
import categoryTypeForm from "./CategoryTypeForm/reducer";
import user from "./User/reducer";
import userForm from "./UserForm/reducer";
import userAuth from "./UserAuth/reducer";

import page from "./Page/reducer";
import pageForm from "./PageForm/reducer";

import testimonial from "./Testimonial/reducer";
import testimonialForm from "./TestimonialForm/reducer";

import settings from "./Settings/reducer";
import settingForm from "./SettingsForm/reducer";

import menuType from "./MenuType/reducer";
import menuTypeForm from "./MenuTypeForm/reducer";

import menu from "./Menu/reducer";
import menuForm from "./MenuForm/reducer";


import team from "./Team/reducer";
import teamForm from "./TeamForm/reducer";

import faqs from "./Faqs/reducer";
import faqsForm from "./FaqsForm/reducer";

import packages from "./Package/reducer";
import packageForm from "./PackageForm/reducer";

import tripinfo from "./Tripinfo/reducer";
import tripinfoForm from "./TripinfoForm/reducer";

import itinerary from "./Itinerary/reducer";
import itineraryForm from "./ItineraryForm/reducer";

import booking from "./Booking/reducer";
import bookingForm from "./BookingForm/reducer";

import album from "./Album/reducer";
import albumForm from "./AlbumForm/reducer";


import media from "./Media/reducer";
import mediaForm from "./MediaForm/reducer";

import logs from "./Logs/reducer"

const rootReducer = combineReducers({
  author: author,
  authorForm: authorForm,
  blog: blog,
  blogForm: blogForm,
  settings: settings,
  settingForm: settingForm,
  category: category,
  categoryForm: categoryForm,
  categoryType: categoryType,
  categoryTypeForm: categoryTypeForm,  
  menuType: menuType,
  menuTypeForm: menuTypeForm,
  menu: menu,
  menuForm: menuForm,
  user: user,  
  article: page,
  articleForm: pageForm,
  userForm: userForm,
  logs: logs,  
  team: team,
  teamForm: teamForm,  
  faqs: faqs,
  faqsForm: faqsForm,  
  testimonial: testimonial,
  testimonialForm: testimonialForm,
  packages: packages,
  packageForm: packageForm,
  tripinfo: tripinfo,
  tripinfoForm: tripinfoForm,
  itineraries: itinerary,
  itineraryForm: itineraryForm,  
  booking: booking,
  bookingForm: bookingForm,  
  album: album,
  albumForm: albumForm,  
  media: media,
  mediaForm: mediaForm,
  loginData: userAuth,
});

export default rootReducer;
